.table {
  width: 100%;
  border-collapse: collapse;
}

.header {
  background-color: #f78429;
  padding: 10px;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
}

.cell {
  border: 1px solid #ddd;
  padding: 8px;
}

.tableContainer {
  overflow: auto;
  max-height: calc(100vh - 200px);
  max-width: 100%;
  flex: 1;
  padding: 0;
  position: relative; /* Ensure proper stacking context */
}

.cell,
.header {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.row {
  cursor: pointer; /* Makes rows clickable */
  transition: background-color 0.2s ease-in-out;
}

.row:hover {
  background-color: rgba(0, 0, 255, 0.1); /* Light blue on hover */
}

.selectedRow {
  background-color: rgba(0, 0, 255, 0.2); /* Darker blue for selected row */
}

.buttonGroup {
  display: flex;
  gap: 10px;
}

.headerTitle {
  margin: 0;
}

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 300px; /* Adjust width as needed */
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.sidebar.show {
  transform: translateX(0);
}

.containerSidebarOpen {
  width: calc(100% - 350px);
  min-width: calc(50% - 45px);
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.sidebarContent {
  flex-grow: 1; /* Ensures it takes available space */
  overflow-y: auto; /* Enables scrolling */
  padding: 10px;
  max-height: calc(100vh - 50px); /* Adjust based on header height */
}

.sheetButton {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 0 0 4px 4px;
  border-width: 1px;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin-right: 10px;
  padding: 5px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  position: relative;
}

.sheetButton:disabled {
  background-color: rgba(51, 51, 51, 0.02);
  color: #999999;
  cursor: not-allowed;
  opacity: 0.7;
  border-color: #dddddd;
  position: relative;
}

.sheetButton:disabled:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: #333333;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  margin-bottom: 5px;
}

.sheetButton:disabled:hover::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
  margin-bottom: -5px;
}

.sheetButton:disabled:hover {
  background-color: rgba(51, 51, 51, 0.02);
}

.sheetButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  margin-top: 0px;
  padding: 10px;
  padding-top: 0px;
  background-color: white;
  border-top: 2px solid #333333;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.sheetButtonGroup {
  display: flex;
  gap: 8px; /* Spacing between sheet buttons */
}

.rightButtons {
  display: flex;
  gap: 8px;
  margin-left: auto; /* Push the right buttons to the far right */
}

.sheetButton:last-child {
  margin-right: 0;
}

.sheetButton:hover {
  background-color: rgba(51, 51, 51, 0.1);
}

.sheetButton.active {
  background-color: #f78429;
  color: white;
}

.sheetButton.active:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: #333333;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  margin-bottom: 5px;
}

.sheetButton.active:hover::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
  margin-bottom: -5px;
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease-in-out;
  overflow: hidden; /* Prevent container from scrolling */
}
